<template>
  <div>
    <div class="w-full flex justify-between items-end mb-4 lg:mb-2">
      <p class="text-xl font-semibold">{{ editJobHeader }}</p>
      <vs-button color="grey" @click.native="close">Cancel</vs-button>
    </div>
    <p v-if="isArchived">Before you reactivate your job, please update any relevant job information.</p>
    <div class="lg:grid grid-cols-10 gap-4 mt-5 h-full">
      <vx-card class="col-span-3 xxl:col-span-2 mb-5 lg:mb-0 bg-white rounded-lg border-solid border-2 border-grey-light">
        <Stepper :edit="edit" :active-step="activeStep" @jump-step="changeStep" />
      </vx-card>
      <vx-card class="col-span-7 xxl:col-span-8 bg-white flex flex-col rounded-lg border-solid border-2 border-grey-light">
        <div>
          <StepOne
            v-if="activeStep === 0"
            key="1"
            ref="stepOne"
            :job-sync="getJobPost"
            :active-step="activeStep"
            :edit-sync="edit"
            :is-archived="isArchived"
            @save-job="submitForm"
            @next-step="activeStep += 1"
            @change-step="(toStep) => (activeStep = toStep)"
          />
          <StepTwo
            v-if="activeStep === 1"
            key="2"
            ref="stepTwo"
            :job-sync="getJobPost"
            :active-step="activeStep"
            :edit-sync="edit"
            :is-archived="isArchived"
            @save-job="submitForm"
            @next-step="activeStep += 1"
            @previous-step="activeStep -= 1"
            @change-step="(toStep) => (activeStep = toStep)"
          />
          <StepThree
            v-if="activeStep === 2"
            key="3"
            ref="stepThree"
            :job-sync="getJobPost"
            :active-step="activeStep"
            :edit-sync="edit"
            :is-archived="isArchived"
            @save-job="submitForm"
            @next-step="activeStep += 1"
            @previous-step="activeStep -= 1"
            @change-step="(toStep) => (activeStep = toStep)"
          />
          <StepFour
            v-if="activeStep === 3"
            key="4"
            ref="stepFour"
            :job-sync="getJobPost"
            :active-step="activeStep"
            :is-archived="isArchived"
            @save-job="submitForm"
            @previous-step="activeStep -= 1"
            @change-step="(toStep) => (activeStep = toStep)"
          />
        </div>
        <div class="flex md:flex-row w-full flex-col-reverse md:w-auto self-center md:self-end md:justify-end px-0 pb-0 md:px-3 md:pb-5">
          <vs-button v-show="activeStep === 0" class="md:mr-3" color="grey" @click.native="close">Cancel</vs-button>
          <portal-target v-show="activeStep > 0" class="md:mr-3 mb-2 md:mb-0" name="previousStepButton"></portal-target>
          <portal-target v-show="activeStep < 3" class="mb-2 md:mb-0" :class="edit ? 'md:mr-3' : ''" name="nextStepButton"></portal-target>
          <!--  -->
          <portal-target v-show="activeStep === 3 || edit" class="mb-2 md:mb-0" name="saveJob"></portal-target>
        </div>
      </vx-card>
    </div>

    <vs-popup title="You are about to spend a credit!" :active.sync="warnJobTypeChange">
      <div class="mb-5">
        <p> You cannot change the Job Type of a Job Post after it has been created. </p>
      </div>
      <vs-button color="primary" type="filled" class="mr-3" @click="warnJobTypeChange = false">Okay</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import Stepper from '../extra-components/stepper/Stepper.vue';
import StepOne from './StepOne.vue';
import StepTwo from './StepTwo.vue';
import StepThree from './StepThree.vue';
import StepFour from './StepFour.vue';

export default {
  components: {
    Stepper,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    jobPost: {
      type: Object,
      required: true,
    },
    beforeEdit: {
      type: Object,
      required: false,
      default: null,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    isArchived: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modalOpenBanner: false,
      warnJobTypeChange: false,
      toolbarOptions: ['bold', 'italic', 'underline', 'strike'],
      editorOption: {
        modules: {
          toolbar: this.toolbarOptions,
        },
      },
      activeStep: 0,
      isDisabled: true,
      name: '',
      category: 'audio',
      order_status: 'pending',
      price: '',
      qualiArray: [],
      newComp: {
        descr: '',
        selected: '',
        name: '',
        type: '',
        number: '',
        website: '',
        email: '',
        accommodation: '',
        meals: '',
        flights: '',
        leave: '',
        tips: '',
        logo: '',
        banner: '',
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    editJobHeader() {
      if (this.isArchived) return 'Reactivate Job';
      if (this.edit) return 'Edit Job';
      return 'Create a Job Post';
    },
    // getJobPost: {
    //   get() {
    //     return this.jobPost;
    //   },
    //   set(value) {
    //     this.$emit('setJob', value);
    //   },
    // },
    getJobPost() {
      return { ...this.jobPost };
    },
    profile() {
      return this.$store.getters['employerModule/getUser'];
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('close-job-creation');
        }
      },
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    cities() {
      return this.$store.getters['countriesModule/getCountries'];
    },
    comp() {
      if (this.$store.getters['companiesModule/getComp'] !== undefined) {
        return this.$store.getters['companiesModule/getComp'];
      } else {
        return this.newComp;
      }
    },
  },
  created() {
    if (this.getJobPost.requiredQual) {
      for (const qual of this.getJobPost.requiredQual) {
        this.qualiArray.push({ value: qual });
      }
    }
  },

  methods: {
    openModalBanner() {
      this.modalOpenBanner = !this.modalOpenBanner;
    },
    changeStep(fromStep, toStep) {
      switch (fromStep) {
        case 0:
          this.$refs.stepOne.changeStep(toStep);
          break;
        case 1:
          this.$refs.stepTwo.changeStep(toStep);
          break;
        case 2:
          this.$refs.stepThree.changeStep(toStep);
          break;
        case 3:
          this.$refs.stepFour.changeStep(toStep);
          break;

        default:
          console.log('Invalid step selected');
          break;
      }
    },
    close() {
      this.$emit('close-job-creation');
      this.$validator.reset();
      this.activeStep = 0;
      this.isSidebarActiveLocal = false;
    },
    addIternary(url) {
      this.job.itinerary = url;
    },
    handleArr(val) {
      if (this.getJobPost.requiredQual.includes(val)) {
        var index = this.jobPost.requiredQual.indexOf(val);
        if (index > -1) {
          this.getJobPost.requiredQual.splice(index, 1);
        }
      } else {
        this.getJobPost.requiredQual.push(val);
      }
    },
    removeArr(val) {
      if (this.getJobPost.requiredQual.includes(val)) {
        var index = this.getJobPost.requiredQual.indexOf(val);
        if (index > -1) {
          this.getJobPost.requiredQual.splice(index, 1);
        }
      }
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.isArchived) return this.$emit('save-archived-job', this.getJobPost);
          console.log(this.getJobPost);
          if (!this.edit) {
            var date = new Date(); // Now
            date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
            this.getJobPost.expire_at = date;
          }
          this.getJobPost.active = true;
          this.getJobPost.employerName = `${this.profile.name} ${this.profile.surname}`;
          this.getJobPost.expired = false;
          if (this.getJobPost.jobDeactivated && this.getJobPost.jobDeactivated === true) {
            this.getJobPost.jobDeactivated = true;
          } else {
            this.getJobPost.jobDeactivated = false;
          }

          this.getJobPost.planType = 0;
          this.getJobPost.jobBanner = this.comp.banner;
          this.getJobPost.jobImage = this.comp.logo;
          this.getJobPost.compName = this.comp.name;
          this.getJobPost.employerId = this.comp.employerId;

          if (this.getJobPost.requiredSkills.constructor !== Array) {
            this.getJobPost.requiredSkills = this.getJobPost.requiredSkills.split(', ');
          }
          if (this.getJobPost.jobType == 'Permanent') {
            if (this.edit) {
              if (this.beforeEdit.jobType === this.getJobPost.jobType) {
                this.postPermanentJob(this.edit);
                return;
              }
              if (this.beforeEdit.jobType !== this.getJobPost.jobType) {
                this.warnJobTypeChange = true;
                return;
              }
            }
            if (!this.edit && this.profile.permanentJobPosts > 0) {
              this.postPermanentJob(this.edit);
              return;
            }
          } else if (this.getJobPost.jobType == 'Freelance') {
            if (this.edit) {
              if (this.beforeEdit.jobType === this.getJobPost.jobType) {
                this.postFreelanceJob(this.edit);
                return;
              }
              if (this.beforeEdit.jobType !== this.getJobPost.jobType) {
                this.warnJobTypeChange = true;
                return;
              }
            }
            if (!this.edit && this.profile.freelanceJobPosts > 0) {
              this.postFreelanceJob(this.edit);
              return;
            }
          }
        }
      });
    },
    postPermanentJob(edit) {
      this.$store.dispatch('jobsModule/set', this.getJobPost);
      this.$store.dispatch('employerModule/postedPermanentJob', edit);
      this.jobSavedNtf();
      this.$emit('close-job-creation');
    },
    postFreelanceJob(edit) {
      this.$store.dispatch('jobsModule/set', this.getJobPost);
      this.$store.dispatch('employerModule/postedFreelanceJob', edit);
      this.jobSavedNtf();
      this.$emit('close-job-creation');
    },

    jobSavedNtf() {
      this.$vs.notify({
        color: 'success',
        title: this.edit ? 'Success!' : 'Success!',
        text: this.edit ? 'Your job has been saved.' : 'Your job has been created.',
      });
    },
  },
};
</script>

<style scoped>
.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
</style>
