<template>
  <div class="pt-4 md:px-3 pb-10">
    <div class="flex items-center text-xl font-semibold">Qualifications</div>
    <div v-if="jobPost.guideType !== 'Lodge Manager' && jobPost.guideType !== 'Management Couple'" class="flex py-6">
      <JobDropdownField
        v-model="jobPost.guideCategory"
        :options="filters['spec_area']['spec_area']"
        error="guide category"
        required="required"
        placeholder="Select guide category"
        title="Guide category"
        size="1"
        class="w-full"
        :allow-empty="false"
      >
      </JobDropdownField>
    </div>
    <div class="flex flex-col md:flex-row pb-6">
      <JobDropdownField
        v-model="jobPost.driversRequired"
        :options="filters['drivers']['drivers']"
        placeholder="Type of drivers licence"
        error="drivers"
        size="1"
        class="w-full md:pr-3 pb-4 md:pb-0"
        title="Drivers licence"
        :allow-empty="false"
      >
      </JobDropdownField>
      <JobDropdownField
        v-model="jobPost.validPDP"
        :options="filters['pdp']['pdp']"
        :allow-empty="false"
        error="pdp"
        required="required"
        placeholder="Is a PDP required?"
        title="PDP required"
        size="1"
        class="w-full"
      >
      </JobDropdownField>
    </div>
    <div class="flex flex-col md:flex-row pb-6"
      ><JobDropdownField
        v-model="jobPost.experience"
        :options="filters['experience']['experience']"
        placeholder="Select job experience"
        error="job experience"
        required="required"
        title="Required experience (years)"
        size="1"
        class="w-full md:pr-3 pb-4 md:pb-0"
      >
      </JobDropdownField>
      <JobDropdownField
        v-if="jobPost.guideType !== 'Lodge Manager' && jobPost.guideType !== 'Management Couple' && jobPost.location === 'South Africa'"
        v-model="jobPost.ndt"
        :options="cities['Kph0kuMjWpANt5Xr7Iqk']['South Africa']"
        :allow-empty="false"
        error="ndt"
        required="required"
        placeholder="Select province"
        title="DEAT / NDT Registered"
        size="1"
        class="w-full"
        :searchable="true"
      >
      </JobDropdownField>
    </div>
    <div class="flex flex-col md:flex-row"
      ><JobDropdownField
        v-model="jobPost.requiredQual"
        :options="filters['qualifications']['qualifications']"
        error="qualifications"
        :required="LMMCNotRequired"
        placeholder="Select qualifications"
        title="Qualifications"
        size="1"
        :limit="4"
        class="w-full md:pr-3 pb-4 md:pb-0"
        :multiple="true"
        :close-on-select="false"
        @select="handleArr"
        @remove="removeArr"
      >
      </JobDropdownField>
    </div>
    <div class="flex flex-col md:flex-row">
      <JobDropdownField
        v-if="jobPost.guideType !== 'Lodge Manager' && jobPost.guideType !== 'Management Couple'"
        v-model="jobPost.nqfLevels"
        :options="filters['nqf']['nqf']"
        placeholder="Select NQF levels"
        title="NQF levels"
        error="nqf"
        size="1"
        class="w-full sm:w-1/2 sm:pr-2"
      >
      </JobDropdownField>
    </div>
    <portal to="previousStepButton">
      <vs-button v-show="activeStep === 2" class="w-full md:w-auto" color="grey" @click="handleClick('previous')">Previous</vs-button>
    </portal>
    <portal to="nextStepButton">
      <vs-button v-if="activeStep === 2" class="w-full md:w-auto" color="primary" @click="handleClick('next')">Next Step</vs-button>
    </portal>
    <portal to="saveJob">
      <vs-button v-if="editSync" type="border" class="w-full md:w-auto" @click="handleClick('save')">{{
        !isArchived ? 'Save job' : 'Reactivate job'
      }}</vs-button>
    </portal>
  </div>
</template>

<script>
import JobDropdownField from './JobDropdownField.vue';

export default {
  components: {
    JobDropdownField,
  },
  props: {
    jobSync: {
      type: Object,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    editSync: {
      type: Boolean,
    },
    isArchived: {
      type: Boolean,
    },
  },
  data() {
    return {
      jobPost: this.jobSync,
    };
  },
  computed: {
    LMMCNotRequired() {
      if (this.jobPost.guideType !== 'Lodge Manager' && this.jobPost.guideType !== 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    LMMCRequired() {
      if (this.jobPost.guideType === 'Lodge Manager' || this.jobPost.guideType === 'Management Couple') {
        return 'required';
      } else {
        return false;
      }
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    cities() {
      return this.$store.getters['countriesModule/getCountries'];
    },
  },
  methods: {
    handleClick(inputType) {
      if (inputType === 'previous' && !this.editSync) {
        this.$emit('previous-step');
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            switch (inputType) {
              case 'save':
                this.$emit('save-job');
                break;
              case 'next':
                this.$emit('next-step');
                break;
              case 'previous':
                this.$emit('previous-step');
                break;
              default:
                break;
            }
          }
        });
      }
    },
    changeStep(toStep) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('change-step', toStep);
        }
      });
    },
    // nextStep() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('next-step');
    //     }
    //   });
    // },
    // previousStep() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('previous-step');
    //       this.$validator.reset();
    //     }
    //   });
    // },
    // saveJob() {
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.$emit('save-job');
    //     }
    //   });
    // },
    handleArr(val) {
      if (this.jobPost.requiredQual.includes(val)) {
        var index = this.jobPost.requiredQual.indexOf(val);
        if (index > -1) {
          this.jobPost.requiredQual.splice(index, 1);
        }
      } else {
        this.jobPost.requiredQual.push(val);
      }
    },
    removeArr(val) {
      if (this.jobPost.requiredQual.includes(val)) {
        var index = this.jobPost.requiredQual.indexOf(val);
        if (index > -1) {
          this.jobPost.requiredQual.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style scoped>
.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
</style>
