<template>
  <nav class="pt-5" aria-label="Progress">
    <ol role="list" class="overflow-hidden">
      <li v-for="(step, index) in steps" :key="index" class="relative pb-5 lg:pb-12">
        <CurrentStep v-if="index === activeStep" :active-step="activeStep" :step="step" />
        <CompletedStep
          v-if="index < activeStep"
          :active-step="activeStep"
          :index="index"
          :step="step"
          @jump-to-step="(fromStep, toStep) => $emit('jump-step', fromStep, toStep)"
        />

        <UpcomingStep
          v-if="index > activeStep"
          :active-step="activeStep"
          :index="index"
          :edit="edit"
          :step="step"
          @jump-to-step="(fromStep, toStep) => $emit('jump-step', fromStep, toStep)"
        />
      </li>
    </ol>
  </nav>
</template>

<script>
import CurrentStep from './CurrentStep.vue';
import CompletedStep from './CompletedStep.vue';
import UpcomingStep from './UpcomingStep.vue';
export default {
  components: {
    UpcomingStep,
    CurrentStep,
    CompletedStep,
  },
  props: {
    activeStep: {
      type: Number,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      steps: [
        { title: 'Required information', desc: 'Basic details of the job' },
        { title: 'Job description', desc: 'Description and skills' },
        { title: 'Qualifications', desc: 'Qualifications and licences' },
        { title: 'Job specifics', desc: 'Remuneration, leave, etc.' },
      ],
    };
  },
  methods: {
    jumpStep(value) {
      console.log(value);
    },
  },
};
</script>
